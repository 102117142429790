import React from 'react';

class Blank extends React.Component {
  render() {
    return (
      <p>Заглушка!!!</p>
    );
  }
}

export default Blank;