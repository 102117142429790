import React from "react"
import Layout from "../components/layout"
import Blank from '../components/blank';
import Loadable from 'react-loadable';
import technologys from '../data/technologys.json';

const LoadableRadar = Loadable({
  loader: () => import('../components/radar'),
  loading: Blank,
});

const allTechnologys = [];
const allCurrentPoints = [];
const allTrendsPoints = [];

Object.keys(technologys).forEach(type => {
  Object.keys(technologys[type].current).forEach(technology => {
    allTechnologys.push(technology);
    allCurrentPoints.push(technologys[type].current[technology].points[0]);
    allTrendsPoints.push(technologys[type].current[technology].points[1] || technologys[type].current[technology].points[0]);
  })
});

const IndexPage = () => (
  <Layout>
    <h2>Trends by Type</h2>
    {
      Object.keys(technologys).map(type => (
        <article>
          <table>
            <tr>
              <th></th>
                <th colspan="2">
                  <strong>Level 0 (current)</strong>
                </th>
                <th>
                  <strong>Level 1 (trend)</strong>
                </th>
              </tr>
              <tr>
                <td rowspan={Object.keys(technologys[type].current).length + 1} width="160">
                  <strong>{type}</strong>
                </td>
              </tr>
              { Object.keys(technologys[type].current).map(technology => {
                const linkedTechnology = technologys[type].current[technology].link ?
                  <a href={technologys[type].current[technology].link}>{technology}</a> :
                  technology;
                if (technologys[type].current[technology].points.length === 2) {
                  return (
                    <tr>
                      <td>{linkedTechnology}</td>
                      <td>{technologys[type].current[technology].points[0]}</td>
                      <td>{technologys[type].current[technology].points[1]}</td>
                    </tr>
                  )
                }
                return (
                <tr>
                  <td>{linkedTechnology}</td>
                  <td>{technologys[type].current[technology].points[0]}</td>
                  <td></td>
                </tr> )
              })}
          </table>
          <LoadableRadar
            categories={Object.keys(technologys[type].current)}
            current={Object.keys(technologys[type].current).map(technology => technologys[type].current[technology].points[0])}
            trends={Object.keys(technologys[type].current).map(technology => technologys[type].current[technology].points[1] || technologys[type].current[technology].points[0])}
          />
            <strong>{Object.keys(technologys[type].maybe).length > 0 ? 'Level 2 (maybe): ' : null }{ Object.keys(technologys[type].maybe).map(technology => (
            <a href={technologys[type].maybe[technology].link} >{technology}</a>
          )) }</strong>
        </article>
      ))
    }
    <h2>All Trends</h2>
    {
      <LoadableRadar
        id={"chart-big"}
        height={900}
        categories={allTechnologys}
        current={allCurrentPoints}
        trends={allTrendsPoints}
      />
    }
  </Layout>
)

export default IndexPage
